<template>
  <g>
    <defs>
      <linearGradient id="8gmn6kidoa" x1="0%" x2="98.048%" y1="82%" y2="18%">
        <stop offset="0%" stop-color="#83CB1B" />
        <stop offset="100%" stop-color="#1BC04A" />
      </linearGradient>
      <linearGradient id="di1ymonngb" x1="0%" x2="98.048%" y1="100%" y2="0%">
        <stop offset="0%" stop-color="#83CB1B" />
        <stop offset="100%" stop-color="#1BC04A" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g>
        <g transform="translate(-135 -188) translate(137.5 190)">
          <g stroke="url(#8gmn6kidoa)" stroke-width="5.25" transform="translate(32 35)">
            <path d="M37.061 0L11.426 29.297 0.439 18.311" />
          </g>
          <circle cx="50" cy="50" r="50" stroke="url(#di1ymonngb)" stroke-width="3" />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
